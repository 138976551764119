import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import { capitalCase } from 'change-case'

export default function DHF({ pageContext }) {
  const { documentNames, productName } = pageContext
  const designPhases = [
    'Overview Documents',
    'Initial Concept',
    'Design Requirements',
    'Specification Development',
    'Design Activities',
    'Design Verification',
    'Design Validation',
    'Pre-Production',
    'Design Release',
  ]
  return (
    <div>
      <Layout title={`${productName} DHF`} filePath={productName}>
        <div className="columns">
          <div className="column is-one-third">
            {designPhases.map((designPhase) => (
              <div className="block">
                <aside className="menu">
                  <p className="menu-label">{designPhase}</p>
                  <ul className="menu-list">
                    {documentNames[designPhase] &&
                      documentNames[designPhase].map((dname) => (
                        <div key={dname}>
                          <Link to={`/${productName}/${designPhase}/${dname}`}>
                            {capitalCase(dname)}
                          </Link>
                        </div>
                      ))}
                  </ul>
                </aside>
              </div>
            ))}
          </div>
        </div>
      </Layout>
    </div>
  )
}
